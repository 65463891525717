<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <datepicker
        :language="datepicker.locale[$i18n.locale.split('-')[0]]"
        format="dd/MM/yyyy"
        :required="required"
        :monday-first="true"
        :bootstrap-styling="true"
        :value="value"
        :placeholder="placeholder"
        @selected="changeVal"
        :name="id"
      >
      </datepicker>

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      {{ $d(new Date(value), 'short') }}

    </template>

  </div>
</template>

<script>

import Datepicker from 'vuejs-datepicker';
import {en, fr} from 'vuejs-datepicker/dist/locale'
import moment from 'moment'

import Field from './_Field'

export default {
  name: "ModuleFormFieldDate",
  extends: Field,
  components: {
    Datepicker,
  },
  data () {
    return {
      datepicker: {
        locale: {
          fr: fr,
          en: en,
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', moment(val).format())
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
::v-deep .form-control[readonly] {
  background: none;
}
</style>
