<template>

  <div class="card">
    <div class="card-header border-0 bg-white rounded pb-0">
      <el-card-title :title="dataForm.id ? $t(`modules.${this.$route.params.module}.${$route.params.page}.buttons.edit`) : $t(`modules.${this.$route.params.module}.${$route.params.page}.buttons.add`)" />
    </div>
    <div class="card-header">
      <form ref="moduleForm">
        <div
          class="form-group"
          :ref="getFormFieldKey(fieldKey)"
          v-for="(fieldConfig, fieldKey, fieldIndex) in fields"
          v-if="(!fieldConfig.display_modes || fieldConfig.display_modes.edit !== false) && displayField(fieldKey)"
        >
          <label
            v-if="
              fieldConfig.type !== 'info' &&
              fieldConfig.type !== 'checkbox' &&
              fieldConfig.type !== 'heading' &&
              fieldConfig.type !== 'formpart' &&
              fieldConfig.type !== 'autocomplete' &&
              fieldConfig.type !== 'separator'
            "
            :for="fieldKey"
            :class="{
              'font-weight-bold text-dark': true,
              'label-required': fieldConfig.required
            }"
            >
            <template v-if="$te(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${fieldKey}.title`)">
              {{ $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${fieldKey}.title`) }}
            </template>
            <template v-else>
              {{ $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${fieldKey}`) }}
            </template>
          </label>

          <container
            :index="fieldIndex"
            :entityType="entity"
            v-if="!fieldConfig.multiple"
            :id="fieldKey"
            :config="fieldConfig"
            :module="module"
            :fields="fields"
            :required="fieldConfig.required ? true : false"
            :type="fieldConfig.type"
            :is="`module-form-field-${fieldConfig.type}`"
            :entity="dataForm"
            v-model="dataForm[fieldKey]"
            :placeholder="
              $te(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${fieldKey}.title`) ?
                $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${fieldKey}.title`) :
                $te(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${fieldKey}`) ?
                  $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${fieldKey}`) :
                  ''"
            mode="edit"
            @removeMedia="mediasToRemove.push($event)"
          >
            Invalid form field type : <strong>{{ fieldConfig.type }}</strong>
          </container>

          <div v-else> <!-- multiple -->

            <div class="mb-1" v-for="(value, valueIndex) in ((dataForm[fieldKey] != '' && dataForm[fieldKey] != []) ? dataForm[fieldKey].length + 1 : 1)">
              <container
                :index="fieldIndex"
                :entityType="entity"
                :id="fieldKey"
                :config="fieldConfig"
                :module="module"
                :required="fieldConfig.required ? true : false"
                :type="fieldConfig.type"
                :is="`module-form-field-${fieldConfig.type}`"
                v-model="dataForm[fieldKey][valueIndex]"
                :placeholder="$te(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${fieldKey}`) && typeof $te(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${fieldKey}`) == 'string' ?
                              $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${fieldKey}`) : ''"
                mode="edit"
                @removeMedia="mediasToRemove.push($event)"
              >
                Invalid form field type : <strong>{{ fieldConfig.type }}</strong>
              </container>
            </div>

          </div> <!-- multiple end -->

          <div v-if="errors && errors[fieldKey] && errors[fieldKey].length > 0">
            <small class="text-danger font-weight-bold"><i class="icon-warning22 small"/> {{ errors[fieldKey][0] }}</small>
          </div>

        </div> <!-- form-group -->

        <div>
          <button :disabled="loading" @click.prevent="submitForm" class="px-3 btn btn-success text-white">
            <span v-if="!loading">
              {{ $t('common.save') }}
            </span>
            <loader v-else/>
          </button>
        </div>
      </form>
    </div>
  </div>

</template>

<script>

import _ from 'lodash'

import Form from './_Form'
import moduleFields from '@/components/module/fields'

export default {
  extends: Form,
  name: 'ModuleForm',
  components: moduleFields,
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    nameFieldKey () {
      return this.config.name_field
    },
  }
}
</script>

<style scoped lang="scss">
img {
  width: auto;
}
::v-deep {
  .resumable-list,
  .form-control,
  .select2 {
    max-width: 800px;
  }
}
</style>
