<template>
  <div>
    <tree
      :identifier="(elem) => elem.id"
      v-if="dataFiltered && dataFormatted"
      :data="dataFormatted"
      node-text="name"
      layoutType="horizontal"
      :zoomable="false"
      popUpPlacement="left"
      :strokeWidth="5"
    >
      <template #popUp="{data}">
        <div class="m-2">
          <div class="card" v-if="data">
            <div class="card-header py-2">
              <h4 class="m-0">
                {{data.data.name}}
              </h4>
            </div>
            <div class="card-body">
              <img width="180" v-if="data.data.image && data.data.image.urls && data.data.image.urls.medium" :src="data.data.image.urls.medium" class="screenshot mb-2"/>
              <div>
                <a :href="data.data.url" class="text-dark border-bottom" target="_new"><i class="icon-new-tab small text-grey mr-1"></i>{{ data.data.url }}</a>
              </div>
              <div class="mt-2" v-if="data.data.description && data.data.description != ''">
                <small>{{data.data.description}}</small>
              </div>
            </div>
            <div class="card-footer bg-transparent border-0 pt-0">
              <div class="d-block text-center btn btn-secondary small-radius text-white btn-sm" v-if="userHasPermission($route.params.module, 'manage_module')" @click="$router.push($route.path + '#' + data.id)">
                {{ $t(`modules.${$route.params.module}.${page}.buttons.view_detail`) }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </tree>
  </div>
</template>

<script>

import {
  MODULE_SET_DATA_FORM,
} from '../../../store/mutations-types'

import {tree} from 'vued3tree'
import _ from 'lodash'

import Index from './_Index'

export default {
  extends: Index,
  name: 'ModuleIndexTree',
  components: {
    tree
  },
  computed: {
    treeKey () {
      return this.render.options.treeKey
    },
    dataFormatted () {
      let data = {
        name: '',
        children: [],
      }
      this.dataFiltered.forEach( element => {
        if (element[this.treeKey]) {
          let category = this.$te(`modules.${this.$route.params.module}.entities.${this.entity}.fields.${this.treeKey}.options`) ? this.$t(`modules.${this.$route.params.module}.entities.${this.entity}.fields.${this.treeKey}.options.${element[this.treeKey]}`) : element[this.treeKey]
          let index = _.findIndex(data.children, (firstLevelChild) => { return category == firstLevelChild.name })
          if (index == -1) { // init[this.treeKey]
            data.children.push({
              id: element[this.treeKey],
              name: category,
              children: [],
            })
          }
          index = _.findIndex(data.children, (firstLevelChild) => { return category == firstLevelChild.name })
          data.children[index].children.push({
            id: element.id,
            name: element.name,
            data: element,
          })
        }
      })
      return data
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">

@import '../../../assets/scss/_palette.scss';

::v-deep .treeclass {
  width: 100%;
  min-height: 80vh;
}
::v-deep .treeclass .nodetree text {
  font-size: 1em;
}
::v-deep .screenshot {
  max-width: 180px;
}
::v-deep .treeclass .linktree {
    stroke: mix(white, $color-info-700);
    stroke-opacity: 1;
}
</style>
