<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">
<!--
      <places
        :required="required"
        :value="addressLabel"
        :options="{
          type: config && config.address_type ? config.address_type : 'address',
          templates: {
            value: function(suggestion) {
              let label = suggestion.name
              if (suggestion.city && suggestion.city && suggestion.city != '') {
                label = label + ', ' + suggestion.city
              }
              else if (suggestion.administrative && suggestion.administrative && suggestion.administrative != '') {
                label = label + ', ' + suggestion.administrative
              }
              if (suggestion.country && suggestion.country != '') {
                label = label + ', ' + suggestion.country
              }
              return label
            }
          }}"
        class="form-control"
        :placeholder="placeholder"
        @change="val => { changeVal(val.hit) }"
      >
      </places>
-->
    <input :required="required" :value="value":type="text" class="form-control w-100" @change="changeVal" :placeholder="placeholder" />

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <a class="float-right" :href="googleMapsUrl" target="_new">
        {{ $t('moduleentity.fields.address.google_maps_link') }}
        <i class="icon-new-tab small"/>
      </a>
      <div v-if="typeof value === 'object'">
        <div>
          <template v-if="value.locale_names && value.locale_names[0]"> {{ value.locale_names[0] }}</template>
        </div>
        <div>
          <template v-if="value.postcode && value.postcode[0]">{{ value.postcode[0] }} </template>
          <span v-if="value.city && value.city[0]">{{ value.city[0] }}</span>
          <span v-else-if="value.county && value.county[0]">{{ value.county[0] }}</span>
        </div>
        <div v-if="value.country">{{ value.country }}</div>
      </div>
      <div v-else>
        {{ value }}
      </div>

    </template>

  </div>
</template>

<script>

// import Places from 'vue-places'

import Field from './_Field'

export default {
  name: "ModuleFormFieldAddress",
  extends: Field,
  // components: {
  //   Places,
  // },
  data () {
    return {
      valueMounted : ''
    }
  },
  mounted () {
    this.valueMounted = this.getValueFromAlgoliaPlacesObjectOrPlain()
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    },
    getValueFromAlgoliaPlacesObjectOrPlain() {
      let address = ''
      if (typeof this.value === 'object') {
        if (this.value.locale_names && this.value.locale_names[0]) {
          address+= `${this.value.locale_names[0]} `
        }
        if (this.value.postcode && this.value.postcode[0]) {
          address+= `${this.value.postcode[0]} `
        }
        if (this.value.city && this.value.city[0]) {
          address+= `${this.value.city[0]} `
        }
        else if (this.value.county && this.value.county[0]) {
          address+= `${this.value.county[0]} `
        }
        if (this.value.country && this.value.country[0]) {
          address+= `${this.value.country} `
        }
      } else {
          address+= this.value
      }
      return address
    },
  },
  computed: {
    addressLabel() {
      if (this.value && this.value.locale_names && this.value.locale_names.length > 0) {
        let label = this.value.locale_names[0]
        if (this.value.city && this.value.city[0] && this.value.city[0] != '') {
          label = label + ', ' + this.value.city[0]
        }
        else if (this.value.administrative && this.value.administrative[0] && this.value.administrative[0] != '') {
          label = label + ', ' + this.value.administrative[0]
        }
        if (this.value.country && this.value.country != '') {
          label = label + ', ' + this.value.country
        }
        return label
      }
      return ''
    },
    googleMapsUrl () {
      let url = 'https://www.google.fr/maps/search/'

      url+= this.getValueFromAlgoliaPlacesObjectOrPlain()

      return url
    },
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
