<template>
  <div class="mb-5">

    <FullCalendar :options="calendarOptions" />

  </div>
</template>

<script>

import moment from 'moment'
import FullCalendar from '@fullcalendar/vue'
import rrulePlugin from "@fullcalendar/rrule";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from '@fullcalendar/core/locales/fr';

import Index from './_Index'

export default {
  extends: Index,
  name: 'ModuleIndexCalendar',
  components: {
    FullCalendar,
  },
  data () {
    return {
      calendarPlugins: [
        dayGridPlugin,
        rrulePlugin,
        interactionPlugin,
      ],
      locales: [
        frLocale,
      ],
    }
  },
  computed: {
    users () {
      return this.$store.getters['world/getUsers']
    },
    usersByKey () {
      return _.keyBy(this.users, 'id')
    },
    dataFormatted () {
      if (!this.dataFiltered) {
        return {}
      }
      let data = []
      this.dataFiltered.forEach( (row, rowIndex) => {
        // Format fields
        _.each(row, (fieldData, fieldKey) => {

          this.dataFiltered[rowIndex].title = this.dataFiltered[rowIndex].name

          if (this.dataFiltered[rowIndex].rrule) {

            // check if recurrent
            let isRecurrent = true
            if (
              this.dataFiltered[rowIndex].rrule.DTSTART &&
              (
                !this.dataFiltered[rowIndex].rrule.FREQ ||
                this.dataFiltered[rowIndex].rrule.FREQ == ''
              )
            ) {
              isRecurrent = false
            }
            if (!isRecurrent) {
              // set start date
              this.dataFiltered[rowIndex].start = this.dataFiltered[rowIndex].rrule.DTSTART
              delete this.dataFiltered[rowIndex].rrule
            } else {
              // format rrule keys
              this.dataFiltered[rowIndex].rrule = _.transform(this.dataFiltered[rowIndex].rrule, function (result, val, key) {
                result[key.toLowerCase()] = val
              })
            }

          }
        })

        if (row.id) {
          // Add link
          row.link = this.$route.path + '#' + row.id
        }

        data.push(row)
      })

      return data
    },
      calendarOptions() {
        return {
          plugins: [
            dayGridPlugin,
            rrulePlugin,
            interactionPlugin,
          ],
          initialView: 'dayGridMonth',
          locale: this.$i18n.locale.split('-')[1],
          locales: [
            frLocale,
          ],
          displayEventTime: false,
          events: this.dataFormatted,
          eventClick: this.eventClick,
          eventResize: this.eventResize,
          eventDrop: this.eventResize,
          editable: false,
          selectable: false,
          eventContent: this.eventContent,
          eventClassNames: this.eventClassNames,
        }
      },
  },
  methods: {
    eventClassNames (info) {
      return !info.event.extendedProps.eventtype ? 'clickable' : 'not-clickable'
    },
    eventContent(info) {
      var node = document.createElement('span')
      let title = info.event.title

      if (!info.event.extendedProps.eventtype) {
        node.setAttribute('class', 'px-1 d-block font-weight-bold cursor-pointer');
      } else if (info.event.extendedProps.eventtype == 'birthday') {
        node.setAttribute('class', 'px-1 d-block text-info');
        title = '<i class="icon-person"></i> ' + this.$t('common.birthday_of', {name: title})
      }
      node.innerHTML = ''
      if (info.event.extendedProps.remindable && info.event.extendedProps.remindable.at_date) {
        node.innerHTML+= '<span class="text-primary mr-1 font-weight-bold"><i class="icon-alarm small"></i><span class="small font-weight-bold">J</span></span>'
      }
      if (info.event.extendedProps.remindable && info.event.extendedProps.remindable.before) {
        node.innerHTML+= '<span class="text-primary mr-1"><i class="icon-alarm small"></i><span class="small font-weight-bold">J' + -info.event.extendedProps.remindable.before + '</span></span>'
      }
      node.innerHTML+= `<span class="word-break">${title}</span>`
      return {html: node.outerHTML}
    },
    eventClick(info) {
      window.location.hash = info.event.id
    },
    eventDrop(info) {
      // console.info('eventDrop', info)
    },
    eventRender(info){
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@fullcalendar/common/main.css';
@import '~@fullcalendar/daygrid/main.css';
::v-deep {
  .fc-view-container {
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.01), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.01), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.03), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.01);
    border-radius: .25rem;
    overflow: hidden;
  }
  .fc-view {
    > table {
      background: white;
      margin: -1px 0px -2px 0px;
      position: relative;
      left: -3px;
      width: calc(100% + 5px);
    }
  }
  .fc-h-event {
    border: none;
    background: none;
  }
  .fc-event-main {
    color: inherit;
  }
  .fc-event-container {
    padding: 1px;
    > div {
      background: #F5F5F5 !important;
      border-radius: 2px;
    }
  }
  .fc-toolbar {
    .fc-left {
      font-size: 0.8em;
      text-transform: capitalize;
    }
  }
}

::v-deep .text-striked {
  text-decoration:line-through;
}

::v-deep .word-break {
  white-space: normal;
  word-break: break-word;
}

::v-deep .fc-daygrid-event {
  &:hover {
    background: none;
  }
  &.clickable {
    &:hover {
      background: rgba(black, 0.03);
    }
  }
}
</style>
