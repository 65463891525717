<template>
  <div>

    <div class="row">
      <div v-for="(place, placeIndex) in dataFiltered" class="col-md-6">
        <a class="card cursor-pointer" :href="'#' + place.id">
          <img v-if="place.photos && place.photos[0]" :src="place.photos[0].urls.large" class="card-img-top bg-light" :alt="place.name">
          <div class="card-body text-dark">
            <h3 class="card-title mb-1 text-dark">{{ place.name }}</h3>
            <div class="my-2">
              <div class="d-flex align-items-center">
                <div><i class="icon-city m-1 mr-4 text-secondary icon-2x"/></div>
                <div v-if="typeof place.address === 'object'">
                  <div v-if="place.address.locale_names && place.address.locale_names[0]"> {{ place.address.locale_names[0] }}</div>
                  <template v-if="place.address.postcode && place.address.postcode[0]">{{ place.address.postcode[0] }} </template>
                  <span v-if="place.address.city && place.address.city[0]">{{ place.address.city[0] }}</span>
                  <span v-else-if="place.address.county && place.address.county[0]">{{ place.address.county[0] }}</span>
                  <div v-if="place.address.country">{{ place.address.country }}</div>
                </div>
                <div v-else>
                  {{ place.address }}
                </div>
              </div>
            </div>
            <div class="text-right"><i class="icon-arrow-right8"/></div>
          </div>
        </a>
      </div>
    </div>

  </div>
</template>

<script>

import Index from './_Index'

export default {
  extends: Index,
  name: 'ModuleIndexPlaces',
  computed: {

  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.card-img-top {
  width: 100%;
  height: 200px;
  max-height: 200px;
  object-fit: cover;
}
</style>

